/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Tooltip } from 'flowbite-react';

export const ExportCSV = ({ csvData, headers, fileName, total }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName, total) => {
    const ws = XLSX.utils.json_to_sheet(csvData, {
      origin: 4,
      skipHeader: true,
    });

    XLSX.utils.sheet_add_json(ws, [{ fileName }], {
      skipHeader: true,
      origin: 'A1',
    });

    var Heading = [headers];
    XLSX.utils.sheet_add_aoa(ws, Heading, {
      origin: 3,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Tooltip content="Export as Excel">
      <button
        onClick={() => exportToCSV(csvData, fileName, total)}
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
          />
        </svg>
      </button>
    </Tooltip>
  );
};
