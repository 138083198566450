import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination } from '@mui/lab';
import { Tooltip, TextInput } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getProjectAction } from '../../redux/action/project';
import FormImage from '../../assets/img/formimage.jpg';
import usePagination from '../../hooks/UsePagination';
import '../../styles/Display.css';
import CustomHeader from '../../component/CustomHeader';
import AccessProject from './AccessProject';

function ProjectsDisplay({ projects }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, allProjects } = useSelector((state) => state.project);
  const [searchView, setSearchView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [openInvite, setOpenInvite] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const filteredData = allProjects.filter((project) =>
    project.projectName.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const handleViewForms = (projects) =>
    navigate(`project/${projects.id}/forms`);
  const handleCreateForm = (projects) =>
    navigate(`project/${projects.id}/create-form`);

  useEffect(() => {
    dispatch(getProjectAction());
  }, [dispatch]);

  // pagination

  const itemsPerPage = 12;

  const paginatedData = usePagination(filteredData, itemsPerPage);

  const handleChange = (e, page) => {
    paginatedData.jump(page);
  };

  const handleClose = () => {
    setOpenInvite(false);
    setOpenRemove(false);
  };

  if (isLoading) {
    return (
      <div className="w-[80px] h-[80px] m-auto animate-pulse absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">
        <img
          src="https://res.cloudinary.com/touch-and-pay-technologies-limited/image/upload/v1654441950/tap-website/logo_gzxyo7.png"
          alt="loading"
        />
      </div>
    );
  }

  return (
    <section className="Display container mx-auto md:px-8 lg:px-10 xl:px-8 py-3">
      <CustomHeader>
        <div className="cursor-pointer">
          <div className="cursor-pointer">
            {searchView ? (
              <div className="w-[240px] md:w-[340px] relative">
                <TextInput
                  id="search"
                  type="search"
                  onBlur={() => {
                    if (searchTerm.length < 1) {
                      setSearchView(false);
                    }
                    if (filteredData?.length <= 0) setSearchTerm('');
                  }}
                  defaultValue={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search Project ..."
                  sizing={'sm'}
                />
                <svg
                  class="absolute text-gray-400 top-1/2 left-4 -translate-y-1/2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            ) : (
              <div onClick={() => setSearchView(true)}>
                <svg
                  className="w-7 h-7"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </CustomHeader>
      <h2 className="pl-4 py-6 font-semibold text-xl uppercase">Projects</h2>
      {filteredData?.length >= 1 ? (
        <>
          <article className="Project-container">
            {paginatedData.currentData()?.map((project, id) => (
              <aside className="Project-cards" key={id}>
                <div className="Project-img_div">
                  <img src={FormImage} alt="form" className="w-full" />
                </div>
                <article className="Project-header">
                  <section className="Project-header_lead">
                    <div>
                      <h3
                        onClick={() => handleViewForms(project)}
                        className="text-md cursor-pointer hover:underline"
                      >
                        {project.projectName}
                      </h3>
                    </div>
                  </section>
                </article>
                <div className="Project-actions_div">
                  <ul className="Project-actions">
                    <Tooltip content="view schema" placement="bottom">
                      <li
                        onClick={() => handleViewForms(project)}
                        className="cursor-pointer text-white pl-1 hover:animate-pulse"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                          <path
                            fillRule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </li>
                    </Tooltip>
                    <Tooltip content="create schema" placement="bottom">
                      <li
                        onClick={() => handleCreateForm(project)}
                        className="cursor-pointer text-white pl-1 hover:animate-pulse"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </li>
                    </Tooltip>
                    <Tooltip content="Invite" placement="bottom">
                      <li
                        onClick={() => setOpenInvite(true)}
                        className="cursor-pointer text-white pl-1 hover:animate-pulse"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
                        </svg>
                      </li>
                    </Tooltip>
                    <AccessProject
                      open={openInvite}
                      handleClose={handleClose}
                      project={project}
                    />
                    <Tooltip content="Remove" placement="bottom">
                      <li
                        onClick={() => setOpenRemove(true)}
                        className="cursor-pointer text-white pl-1 hover:animate-pulse"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path d="M10.375 2.25a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM10.375 12a7.125 7.125 0 00-7.124 7.247.75.75 0 00.363.63 13.067 13.067 0 006.761 1.873c2.472 0 4.786-.684 6.76-1.873a.75.75 0 00.364-.63l.001-.12v-.002A7.125 7.125 0 0010.375 12zM16 9.75a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z" />
                        </svg>
                      </li>
                    </Tooltip>
                    <AccessProject
                      open={openRemove}
                      handleClose={handleClose}
                      project={project}
                      remove
                    />
                  </ul>
                </div>
              </aside>
            ))}
          </article>
          <Pagination
            count={paginatedData.maxPage}
            size="medium"
            page={paginatedData.currentPage}
            variant="outlined"
            shape="rounded"
            border="border-primary"
            onChange={handleChange}
            className="container mx-auto flex justify-center mt-7"
            showFirstButton
            showLastButton
          />
        </>
      ) : (
        <div className="h-[70vh] flex justify-center items-center">
          <section className="px-6 py-4 rounded-md border">
            <h3>Project does not exist</h3>
          </section>
        </div>
      )}
    </section>
  );
}

export default ProjectsDisplay;
