import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WithContext as ReactTags } from 'react-tag-input';
import { useFormik, ErrorMessage, FormikProvider } from 'formik';
import * as yup from 'yup';
import { Button, Checkbox, Label, Modal, Spinner } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  disableAccessProjectAction,
  enableAccessProjectAction,
} from '../../redux/action/project';
import { storageSet } from '../../utils';
import { ToastMessage } from '../../component/ToastMessage';
import '../../styles/AccessProject.css';

const KeyCodes = {
  comma: 188,
  enter: 13,
  tab: 9,
  space: 32,
};

const delimiters = [
  KeyCodes.comma,
  KeyCodes.enter,
  KeyCodes.tab,
  KeyCodes.space,
];

function AccessProject({ open, handleClose, remove, project }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { successMessage, errorMessage } = useSelector(
    (state) => state.project,
  );
  const [tags, setTags] = React.useState([]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    console.log(tag);
    setFieldValue('email', [tags.email]);
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const formik = useFormik({
    initialValues: {
      email: remove ? '' : [],
      isOwner: false,
    },
    validationSchema: yup.object().shape({
      email: !remove
        ? yup
            .array()
            .transform(function (value, originalValue) {
              if (this.isType(value) && value !== null) {
                return value;
              }
              return originalValue ? originalValue.split(/[\s,]+/) : [];
            })
            .of(
              yup
                .string()
                .email(({ value }) => `${value} is not a valid email`),
            )
        : yup.string().email().required(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const newValues = {
        email: remove
          ? values.email
          : String(tags.map((item) => item.email)) || values.email,
        projectID: project.id,
      };

      const response = remove
        ? await dispatch(disableAccessProjectAction(newValues))
        : await dispatch(
            enableAccessProjectAction({
              ...newValues,
              isOwner: values.isOwner,
            }),
          );
      if (response?.success?.status === 1) {
        setIsSuccess(true);
      } else {
        setIsError(true);
      }
    },
  });

  const {
    handleSubmit,
    handleBlur,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  const handleCloseToast = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  return (
    <Modal show={open} size="md" popup={true} onClose={handleClose}>
      <Modal.Header>{remove ? 'Remove' : 'Invite'}</Modal.Header>

      <FormikProvider value={formik}>
        <form className="mt-5" onSubmit={handleSubmit}>
          <Modal.Body>
            {!remove ? (
              <>
                <ReactTags
                  tags={tags}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={(tag) => {
                    console.log(tag);
                    setFieldValue('email', tag.text);
                    handleAddition(tag);
                  }}
                  handleInputChange={(val) => {
                    console.log(val);
                    setFieldValue('email', val);
                  }}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="bottom"
                  labelField="email"
                  placeholder="Please Enter Emails"
                />
                <ErrorMessage
                  name="email"
                  render={(msg) => (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-400"
                    >
                      <span className="font-medium">{msg}</span>
                    </p>
                  )}
                />
                <div className="flex items-center gap-2 mt-4 justify-end">
                  <Checkbox
                    id="isOwner"
                    {...getFieldProps('isOwner')}
                    onChange={(e) => setFieldValue('isOwner', e.target.checked)}
                    defaultChecked={values.isOwner}
                  />
                  <Label htmlFor="isOwner">Is user an Owner?</Label>
                </div>
              </>
            ) : (
              <div className="relative my-6">
                <input
                  type="text"
                  onBlur={handleBlur}
                  {...getFieldProps('email')}
                  className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#f47f30] dark:focus:border-[#f47f30] focus:outline-none focus:ring-0 focus:border-[#f47f30] peer"
                  placeholder=" "
                />
                <label
                  htmlFor="email"
                  className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-[#f47f30] peer-focus:dark:text-[#f47f30] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                >
                  Email
                </label>
                <ErrorMessage
                  name="email"
                  render={(msg) => (
                    <p
                      id="filled_error_help"
                      className="mt-2 text-xs text-red-400"
                    >
                      <span className="font-medium">{msg}</span>
                    </p>
                  )}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="bg-[#f47f30] py-[10px] px-4 rounded-lg text-white text-sm"
              onClick={handleSubmit}
              type="button"
            >
              {isSubmitting ? <Spinner /> : remove ? 'Remove' : 'Invite'}
            </button>
            <Button color="gray" onClick={handleClose} type="button">
              Decline
            </Button>
          </Modal.Footer>
        </form>
        {isSuccess && (
          <ToastMessage
            message={successMessage}
            success
            handleClose={handleCloseToast}
          />
        )}
        {isError && (
          <ToastMessage
            message={errorMessage}
            error
            handleClose={handleCloseToast}
          />
        )}
      </FormikProvider>
    </Modal>
  );
}

export default AccessProject;
