import { Route, Routes } from 'react-router-dom';
import Login from './pages/Auth/Login';
import ValidateLogin from './pages/Auth/ValidateLogin';
import CreateForm from './pages/Forms/CreateForm';
import Index from './pages/Projects';
import ViewForm from './pages/Forms/ViewForm';
import CreateProject from './pages/Projects/CreateProject';
import './App.css';
import FormsDisplay from './pages/Forms/FormsDisplays';
import FormResponse from './pages/Forms/FormResponse';
import { FormData } from './pages/Forms/FormData';
import Page404 from './pages/404';

import { storageGet } from './utils';

function App() {
  const token = storageGet('token');
  return (
    <main className="App py-4">
      <Routes>
        {token ? (
          <>
            <Route path="/" element={<Index />} />
            <Route path="/create" element={<CreateProject />} />
            <Route path="/project/:id/create-form" element={<CreateForm />} />
            <Route
              path="/project/:id/edit-form/:formID"
              element={<CreateForm />}
            />
            <Route path="/project/:id/forms" element={<FormsDisplay />} />
            <Route
              path="/project/:id/forms/:formID/preview"
              element={<ViewForm />}
            />
            <Route
              path="/:id/forms/:formID/formResponse"
              element={<FormResponse />}
            />
            <Route
              path="/project/:id/forms/:formID/data"
              element={<FormData />}
            />
            <Route path="*" element={<Page404 />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/validate" element={<ValidateLogin />} />
            <Route
              path="/project/:id/forms/:formID/preview"
              element={<ViewForm />}
            />
            <Route
              path="/:id/forms/:formID/formResponse"
              element={<FormResponse />}
            />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Page404 />} />
          </>
        )}
      </Routes>
    </main>
  );
}

export default App;
