import {
  LoadActionTypes,
  MessageActionTypes,
  ProjectActionTypes,
} from '../types';
import { formatRequestPayload } from '../../utils';
import {
  creatProjectRequest,
  getProjectRequest,
  enableAccessProjectRequest,
  disableAccessProjectRequest,
} from '../../api/project';

export const createProjectAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await creatProjectRequest(formatRequestPayload(values));
    console.log(data.error.message);

    if (data.success?.status === 1) {
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data.success.message,
      });
    }
    if (data.error?.status === 1) {
      dispatch({
        type: MessageActionTypes.ERROR_MESSAGE,
        payload: data.error.message,
      });
    }

    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getProjectAction = () => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });
    const data = await getProjectRequest();
    if (data.success.status === 1) {
      dispatch({
        type: ProjectActionTypes.GET_PROJECTS,
        payload: data.content.data,
      });
    }
    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const enableAccessProjectAction = (values) => async (dispatch) => {
  try {
    const data = await enableAccessProjectRequest(formatRequestPayload(values));

    if (data.success?.status === 1) {
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data.success.message,
      });
    }
    if (data.error?.status === 1) {
      dispatch({
        type: MessageActionTypes.ERROR_MESSAGE,
        payload: data.error.message,
      });
    }

    return data;
  } catch (error) {}
};

export const disableAccessProjectAction = (values) => async (dispatch) => {
  try {
    // dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await disableAccessProjectRequest(
      formatRequestPayload(values),
    );

    if (data.success?.status === 1) {
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data.success.message,
      });
    }
    if (data.error?.status === 1) {
      dispatch({
        type: MessageActionTypes.ERROR_MESSAGE,
        payload: data.error.message,
      });
    }

    return data;
  } catch (error) {}
};
