export const camelCase = (str = '') => {
  return str
    .split(' ')
    .map((str, index) => {
      if (index === 0) return str.toLowerCase();
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    })
    .join('');
};

export const reverseCamelCase = (str = '') => {
  return str
    .replace(/[0-9]/g, '')
    .replace(/([A-Z])/g, ' $&')
    .split(' ')
    .map((str) => {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    })
    .join(' ');
};
