import {
  LoadActionTypes,
  MessageActionTypes,
  SchemaActionTypes,
} from '../types';

const initialState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  allSchemas: [],
  schema: null,
  schemaOptions: {},
  schemaData: [],
  schemaFilterObj: [],
};

export const schemaReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoadActionTypes.LOADING_CONTENT:
      return { ...state, isLoading: true };

    case MessageActionTypes.SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload, isLoading: false };

    case MessageActionTypes.ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload, isLoading: false };

    case SchemaActionTypes.GET_SCHEMAS:
      return { ...state, allSchemas: action.payload, isLoading: false };

    case SchemaActionTypes.GET_SCHEMA:
      return { ...state, schema: action.payload, isLoading: false };

    case SchemaActionTypes.GET_SCHEMA_OPTIONS:
      return { ...state, schemaOptions: action.payload, isLoading: false };

    case SchemaActionTypes.GET_SCHEMA_DATA:
      return { ...state, schemaData: action.payload, isLoading: false };

    case SchemaActionTypes.GET_SCHEMA_FILTER_OBJ:
      return { ...state, schemaFilterObj: action.payload, isLoading: false };

    case LoadActionTypes.LOAD_DONE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
