// import React from "react";
import OtpInput from 'react-otp-input';
import '../styles/Otp.css';

const Otp = ({ onChange, code }) => {
  return (
    <div className="flex justify-center">
      <OtpInput
        value={code}
        onChange={onChange}
        numInputs={6}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={{
          margin: '0 4px',
          fontSize: '24px',
          borderRadius: 10,
          border: '1px solid #ACACAC',
          caretColor: '#0E1F3F',
        }}
        focusStyle={{
          border: '1px solid #0E1F3F',
          outline: 'none',
        }}
        className="otp-input"
      />
    </div>
  );
};

export default Otp;
