import axios from '.';

export const creatSchemaRequest = (values) =>
  axios.post('/create/schema', values).then((res) => res.data);

export const getSchemaByProjectRequest = (projectID) =>
  axios.get(`/fetch/schemaByProject/${projectID}`).then((res) => res.data);

export const getSchemaRequest = (schemaID) =>
  axios.get(`/fetch/schemaByID/${schemaID}`).then((res) => res.data);

export const creatSchemaDataRequest = (values) =>
  axios.post('/add/data', values).then((res) => res.data);

export const getSchemaDataRequest = (values) =>
  axios.post('/fetch/data', values).then((res) => res.data);

export const getSchemaFilterObjRequest = (schemaID) =>
  axios.get(`/fetch/filterSchemaByID/${schemaID}`).then((res) => res.data);

export const createSchemaDataSuccessRedirectRequest = (params) =>
  axios.get(`/redirect?redr=${params}`).then((res) => res.data);
