import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CreateFormComponent from './CreateFormComponent';
import { getProjectAction } from '../../redux/action/project';
import { getSchemaAction } from '../../redux/action/schema';
import ConfirmModal from '../../component/ConfirmModal';

function CreateForm() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const { allProjects } = useSelector((state) => state.project);
  const { schema } = useSelector((state) => state.schema);

  useEffect(() => {
    dispatch(getProjectAction());
  }, [dispatch]);

  const getSchema = useCallback(() => {
    dispatch(getSchemaAction(params?.formID));
  }, [dispatch, params?.formID]);

  useEffect(() => {
    getSchema();
  }, [getSchema]);

  const currentProject = allProjects?.find(
    (project, id) => params?.id === project.id,
  );

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleBack = () => {
    navigate(`/project/${params?.id}/forms`);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = () => {
      setOpenModal(true);
    };

    // logic for showing popup warning on page refresh
    window.onbeforeunload = function () {
      return 'Data will be lost if you refresh the page, are you sure?';
    };
  }, []);

  return (
    <>
      <div onClick={handleOpen} className="cursor-pointer">
        <aside className="text-gray-500 rounded-md px-2 py-2 flex fixed top-5 left-5 lg:top-10 lg:left-10 z-50">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p className="ml-2"> Back</p>
        </aside>
      </div>
      <ConfirmModal
        open={openModal}
        onClose={handleClose}
        handleConfirm={handleBack}
        header={'Confirm'}
        children={
          <p className="text-center">Are you sure you want to go back?</p>
        }
      />
      <h1 className="text-center font-semibold text-xl mt-16 lg:mt-8">
        {location?.pathname.includes('edit') ? 'Edit Schema' : 'Create Schema'}
      </h1>
      <section className="container relative px-2 md:px-10 lg:px-60 mx-auto my-20">
        {params?.formID ? (
          <CreateFormComponent
            projectID={currentProject?.id}
            currentSchema={schema}
            isEdit
          />
        ) : (
          <CreateFormComponent projectID={currentProject?.id} />
        )}
      </section>
    </>
  );
}

export default CreateForm;
