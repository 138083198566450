import {
  getSchemaByProjectRequest,
  getSchemaRequest,
  creatSchemaRequest,
  getSchemaDataRequest,
  getSchemaFilterObjRequest,
  creatSchemaDataRequest,
  createSchemaDataSuccessRedirectRequest,
} from '../../api/schema';
import { formatRequestPayload } from '../../utils';
import {
  LoadActionTypes,
  MessageActionTypes,
  SchemaActionTypes,
} from '../types';

export const getSchemaByProjectAction = (projectID) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });
    const data = await getSchemaByProjectRequest(projectID);

    if (data.success.status === 1) {
      dispatch({
        type: SchemaActionTypes.GET_SCHEMAS,
        payload: data.content.schema,
      });
    }
    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const getSchemaAction = (schemaID) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });
    const data = await getSchemaRequest(schemaID);
    if (data?.success?.status === 1) {
      dispatch({
        type: SchemaActionTypes.GET_SCHEMA,
        payload: data.content.schema,
      });
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data?.success?.message,
      });
    } else {
      dispatch({
        type: MessageActionTypes.ERROR_MESSAGE,
        payload: data?.error?.message,
      });
    }
    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const getSchemaOptionsAction = (schemaID) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });
    const data = await getSchemaRequest(schemaID);
    console.log(data);
    if (data.success.status === 1) {
      dispatch({
        type: SchemaActionTypes.GET_SCHEMA_OPTIONS,
        payload: data.content.schema,
      });
    }
    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const createSchemaAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await creatSchemaRequest(formatRequestPayload(obj));

    // console.log(response);
    if (data?.success?.status === 1) {
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data?.success?.message,
      });
      dispatch(getSchemaByProjectAction(data?.content?.schema?.projectID));
    } else {
      dispatch({
        type: MessageActionTypes.ERROR_MESSAGE,
        payload: data?.error?.message,
      });
    }

    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const createSchemaDataAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await creatSchemaDataRequest(formatRequestPayload(values));

    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const getSchemaDataAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await getSchemaDataRequest(formatRequestPayload(values));

    if (data.success.status === 1) {
      dispatch({
        type: SchemaActionTypes.GET_SCHEMA_DATA,
        payload: data.content.data,
      });
    }

    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const getSchemaFilterObjAction = (schemaID) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await getSchemaFilterObjRequest(schemaID);

    if (data.success.status === 1) {
      dispatch({
        type: SchemaActionTypes.GET_SCHEMA_FILTER_OBJ,
        payload: data.content.schema,
      });
    }

    dispatch({ type: LoadActionTypes.LOAD_DONE });
    return data;
  } catch (error) {}
};

export const createSchemaDataSuccessRedirectAction =
  (params) => async (dispatch) => {
    try {
      dispatch({ type: LoadActionTypes.LOADING_CONTENT });

      const data = await createSchemaDataSuccessRedirectRequest(params);

      dispatch({ type: LoadActionTypes.LOAD_DONE });
      return data;
    } catch (error) {
      console.log(error);
    }
  };
