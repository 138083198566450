const storage_prefix =
  process.env.NODE_ENV === 'development' ? 'its_dev_' : 'its_prod_';

const getKeyName = (key) => `${storage_prefix}${key}`;
const checkStoreKey = (key) => localStorage.key(key);

const encryptStoreData = (data) => window.btoa(data);
const decryptStoreData = (data) => window.atob(data);

export const storageSet = (key, data) => {
  let storeData = JSON.stringify(data);
  storeData = encryptStoreData(storeData);
  localStorage.setItem(getKeyName(key), storeData);
};

export const storageRemove = (key) => {
  let storeKey = getKeyName(key);
  if (checkStoreKey(storeKey)) {
    return localStorage.removeItem(storeKey);
  }
};

export const storageGet = (key) => {
  let storeKey = getKeyName(key);
  let check = checkStoreKey(storeKey);
  if (!check) return null;

  let data = localStorage.getItem(storeKey);
  if ((check || !check) && (!data || data === 'undefined' || data === null)) {
    storageRemove(storeKey);
    return null;
  }
  data = decryptStoreData(data);
  data = JSON.parse(data);
  return data;
};

export function formatRequestPayload(payload) {
  return { data: { ...payload } };
}
