import {
  LoadActionTypes,
  MessageActionTypes,
  ProjectActionTypes,
} from '../types';

const initialState = {
  isLoading: false,
  successMessage: '',
  errorMessage: '',
  allProjects: [],
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case LoadActionTypes.LOADING_CONTENT:
      return { ...state, isLoading: true };

    case MessageActionTypes.SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload, isLoading: false };

    case MessageActionTypes.ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload, isLoading: false };

    case ProjectActionTypes.GET_PROJECTS:
      return { ...state, allProjects: action.payload, isLoading: false };
    case LoadActionTypes.LOAD_DONE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
