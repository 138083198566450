import axios from 'axios';
import { storageGet, storageRemove } from '../utils';

const dev = process.env.NODE_ENV !== 'production';

const Base_Url = dev
  ? 'https://openforms.touchandpay.me/v1'
  : 'https://dynamicformapi-n-bfbwefcgedardabv.uksouth-01.azurewebsites.net/v1';

const axiosInstance = axios.create({
  baseURL: Base_Url,
});

axiosInstance.interceptors.request.use((config) => {
  const token = storageGet('token');

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    };
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  if (
    response.status === 400 ||
    response.data.error.message === 'Access Denied! Authentication is invalid'
  ) {
    storageRemove('token');
    window.location.pathname = '/login';
  }
  return response;
});

export default axiosInstance;
