import React from 'react';
import '../styles/switchStyles.css';
function SwitchComponent({ name, setChange, ...others }) {
  return (
    <div className="mx-4">
      <label className="switch w-full">
        <input name={name} type="checkbox" onChange={setChange} {...others} />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default SwitchComponent;
