export const LoadActionTypes = {
  LOADING_CONTENT: 'LOADING_CONTENT',
  LOAD_DONE: 'LOAD_DONE',
};

export const MessageActionTypes = {
  SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
};

export const ProjectActionTypes = {
  GET_PROJECTS: 'GET_PROJECTS',
};

export const SchemaActionTypes = {
  GET_SCHEMAS: 'GET_SCHEMAS',
  GET_SCHEMA: 'GET_SCHEMA',
  GET_SCHEMA_OPTIONS: 'GET_SCHEMA_OPTIONS',
  GET_SCHEMA_DATA: 'GET_SCHEMA_DATA',
  GET_SCHEMA_FILTER_OBJ: 'GET_SCHEMA_FILTER_OBJ',
};
