import React from 'react';

function SelectDisplay({ type }) {
  const setField = (type) => {
    switch (type) {
      case 'text':
        return;
      case 'number':
        return '';
      case 'dropdown':
        return;
      case 'date':
        return (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        );
      case 'datetime-local':
        return (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      default:
        return '';
    }
  };

  return (
    <aside className="relative pr-2 my-4 md:my-0 flex items-center justify-between w-full md:w-72 bg-gray-50 dark:bg-gray-700">
      <input
        type={type}
        id="fieldName"
        name="fieldName"
        className="block w-full m-0 capitalize rounded-t-lg px-2.5 pb-2.5 pt-5 text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#f47f30] peer"
        // value={type === "number" || type === "text" ? `${type}` : ""}
        value={
          type === 'number' ? Number('0') : type === 'text' ? `${type}` : ''
        }
        disabled
      />
      <label
        htmlFor="fieldName"
        className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-[#f47f30] peer-focus:dark:text-[#f47f30] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
      >
        {`Selected Field Type - ${type || ''}`}
      </label>
      {setField(type)}
    </aside>
  );
}

export default SelectDisplay;
