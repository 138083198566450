import { Link } from 'react-router-dom';
import ProjectsDisplay from './ProjectsDisplay';

function Index() {
  return (
    <>
      <section className="py-6 flex justify-center items-center fixed bottom-0 right-0 -translate-y-20 -translate-x-6 lg:-translate-x-6 z-50">
        <aside className="bg-red-500 text-white w-10 h-10 rounded-full flex justify-center items-center cursor-pointer ">
          <Link to="/create">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </aside>
      </section>

      <ProjectsDisplay />
    </>
  );
}

export default Index;
