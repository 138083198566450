import React from 'react';

function CustomInput({ label, name, onChange, ...others }) {
  return (
    <>
      <div className="relative mb-6 w-full">
        <input
          type="text"
          onChange={onChange}
          id={name}
          name={name}
          {...others}
          className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#f47f30] dark:focus:border-[#f47f30] focus:outline-none focus:ring-0 focus:border-[#f47f30] peer"
          placeholder=""
        />
        <label
          htmlFor={name}
          className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-[#f47f30] peer-focus:dark:text-[#f47f30] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
        >
          {label}
        </label>
      </div>
    </>
  );
}

export default CustomInput;
