import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, TextInput } from 'flowbite-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getSchemaByProjectAction } from '../../redux/action/schema';
import { getProjectAction } from '../../redux/action/project';
import FormImage from '../../assets/img/formimage.jpg';
import CustomHeader from '../../component/CustomHeader';

function FormsDisplay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { allSchemas, isLoading } = useSelector((state) => state.schema);
  const { allProjects } = useSelector((state) => state.project);
  const [searchView, setSearchView] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = allSchemas.filter((schema) =>
    schema.schemaName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const currentProject = allProjects?.find(
    (projects) => projects.id === params?.id,
  );

  const [copied, setCopied] = useState(false);

  const handleFormPreview = (schema) => {
    navigate(`/project/${params?.id}/forms/${schema.id}/preview`);
  };

  const handleFormData = (schema) => {
    navigate(`/project/${params?.id}/forms/${schema.id}/data`);
  };

  const handleLinkCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  const handleFormEdit = (schema) => {
    navigate(`/project/${params?.id}/edit-form/${schema.id}`);
  };
  const getSchema = useCallback(() => {
    if (params?.id) {
      dispatch(getSchemaByProjectAction(params?.id));
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    getSchema();
    setCopied(false);
  }, [getSchema]);

  const getProjects = useCallback(() => {
    dispatch(getProjectAction());
  }, [dispatch]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const dev = process.env.NODE_ENV !== 'production';
  const domainURL = dev
    ? 'https://forms-staging.netlify.app'
    : 'https://form.touchandpay.me';

  if (isLoading) {
    return (
      <div className="w-[80px] h-[80px] m-auto animate-pulse absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">
        <img
          src="https://res.cloudinary.com/touch-and-pay-technologies-limited/image/upload/v1654441950/tap-website/logo_gzxyo7.png"
          alt="loading"
        />
      </div>
    );
  }
  return (
    <section className="Display container mx-auto md:px-8 lg:px-10 xl:px-8 relative">
      <CustomHeader>
        <div className="cursor-pointer">
          <div className="cursor-pointer">
            {searchView ? (
              <div className="w-[240px] md:w-[340px] relative">
                <TextInput
                  id="search"
                  type="search"
                  onBlur={() => {
                    if (searchTerm.length < 1) {
                      setSearchView(false);
                    }
                    if (filteredData?.length <= 0) setSearchTerm('');
                  }}
                  defaultValue={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search Project ..."
                  sizing={'sm'}
                />
                <svg
                  class="absolute text-gray-400 top-1/2 left-4 -translate-y-1/2 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            ) : (
              <div onClick={() => setSearchView(true)}>
                <svg
                  className="w-7 h-7"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </CustomHeader>
      <div className="py-6 px-5">
        <h2 className="mb-2 font-semibold text-xl uppercase">
          {currentProject?.projectName}
        </h2>
        <p className="">{currentProject?.projectDetails}</p>
      </div>

      {filteredData.length >= 1 ? (
        <div className="grid bg-white grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 place-items-center">
          {filteredData?.map((schema, id) => (
            <aside className="w-[250px] p-2 shadow-lg m-4 " key={id}>
              <div className="shadow-md w-[180px] mx-auto my-2 ">
                <img src={FormImage} alt="form" className="w-full" />
              </div>
              <article className="my-4 p-2 border-t-2 border-[#f47f30]">
                <section className="flex justify-between items-end mb-2">
                  <div>
                    <h3 className="text-md">{schema.schemaName}</h3>
                    <p className="">Schema ID: {schema?.id}</p>
                  </div>
                </section>
              </article>

              <ul className="py-2 flex gap-2 justify-end items-center">
                <Tooltip content="Edit" placement="bottom">
                  <li
                    onClick={() => handleFormEdit(schema)}
                    className="cursor-pointer bg-white pl-1 my-2 hover:animate-bounce"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                    </svg>
                  </li>
                </Tooltip>
                <Tooltip content="Preview" placement="bottom">
                  <li
                    onClick={() => handleFormPreview(schema)}
                    className="cursor-pointer bg-white pl-1 my-2 hover:animate-bounce"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                      />
                    </svg>
                  </li>
                </Tooltip>
                <Tooltip content="Data" placement="bottom">
                  <li
                    onClick={() => handleFormData(schema)}
                    className="cursor-pointer bg-white pl-1 my-2 hover:animate-bounce"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </li>
                </Tooltip>
                <Tooltip content="Generate link" placement="bottom">
                  <CopyToClipboard
                    text={`${domainURL}${location.pathname}/${schema.id}/preview`}
                    onCopy={() => setCopied({ copied: true })}
                  >
                    <li
                      onClick={handleLinkCopy}
                      className="cursor-pointer bg-white pl-1 hover:animate-bounce"
                    >
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                        />
                      </svg>
                    </li>
                  </CopyToClipboard>
                </Tooltip>
              </ul>
            </aside>
          ))}
        </div>
      ) : (
        <div className="h-[70vh] flex justify-center items-center">
          <section className="px-6 py-4 rounded-md border">
            <h3>No schemas available.</h3>
            <Link to={`/`}>
              <small className="text-[#f47f30]">Go Home</small>
            </Link>
          </section>
        </div>
      )}

      {copied && (
        <div className="Toast py-2 px-6 capitalize shadow-md bg-white absolute top-[20px] left-2/4 -translate-x-1/2">
          <p className="text-green-400">Link Copied Successfully</p>
        </div>
      )}
      <section className="py-6 flex justify-center items-center fixed bottom-0 right-0 -translate-y-20 -translate-x-6 lg:-translate-x-6">
        <article>
          <aside
            className="bg-red-500 text-white w-10 h-10 rounded-full flex justify-center items-center cursor-pointer"
            // onClick={handleCreateForm()}
          >
            <Link to={`/project/${params.id}/create-form`}>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </aside>
        </article>
      </section>
    </section>
  );
}

export default FormsDisplay;
