import { useState } from 'react';
import { Tooltip } from 'flowbite-react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { logoutAction } from '../redux/action/auth';
import ConfirmModal from './ConfirmModal';

function CustomHeader({ children }) {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <header className="sticky py-5 px-5">
      <nav className="flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="w-[50px] flex items-center ">
            <img
              src="https://frontendgroupdiag.blob.core.windows.net/websiteresource/tapsite/logo.png"
              alt="loading"
            />
          </Link>
          <h3 className="hidden md:block ml-4 uppercase font-bold tracking-wider">
            TAP Forms
          </h3>
        </div>
        <div className="flex gap-x-8">
          <div>{children}</div>
          <div>
            <Tooltip content="logout" placement="bottom">
              <button type="button" onClick={() => setOpenModal(true)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                  />
                </svg>
              </button>
            </Tooltip>
          </div>
        </div>
      </nav>
      <ConfirmModal
        open={openModal}
        handleConfirm={() => dispatch(logoutAction())}
        children={
          <p className="text-center">Are you sure you want to logout?</p>
        }
        onClose={handleClose}
      />
    </header>
  );
}

export default CustomHeader;
