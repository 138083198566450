import React from 'react';
import { useParams, Link } from 'react-router-dom';

function FormResponse() {
  const { formID, id } = useParams();
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <section className="px-6 py-4 rounded-md border">
        <h3>Your Response ( ID : {formID}) has been Recorded</h3>
        <Link to={`/project/${id}/forms/${formID}/preview`}>
          <small className="text-[#f47f30]">Submit another response</small>
        </Link>
      </section>
    </div>
  );
}

export default FormResponse;
