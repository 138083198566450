import React from 'react';
import Select from 'react-select';
function ViewDropdown({ behavior, data, onChange }) {
  const items = data?.map((item) => {
    return { value: item, label: item };
  });
  // eslint-disable-next-line
  const searchable = (behavior) => behavior.find((value) => value === 7);

  return (
    <Select
      isClearable
      options={items}
      onChange={onChange}
      isSearchable={behavior.includes(7)}
    />
  );
}

export default ViewDropdown;
