import { LoadActionTypes, MessageActionTypes } from '../types';
import { formatRequestPayload, storageRemove, storageSet } from '../../utils';
import { loginRequest } from '../../api/auth';

export const loginAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LoadActionTypes.LOADING_CONTENT });

    const data = await loginRequest(formatRequestPayload(values));
    if (data.success?.status === 1) {
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data.success.message,
      });
    }
    if (data.success?.status === 1 && data.content?.auth) {
      console.log('here');
      setAuthorization(data.content);
      dispatch({
        type: MessageActionTypes.SUCCESS_MESSAGE,
        payload: data.success.message,
      });
    }
    if (data.error?.status === 1) {
      dispatch({
        type: MessageActionTypes.ERROR_MESSAGE,
        payload: data.error.message,
      });
    }

    console.log(data);

    return data;
  } catch (error) {
    console.log(error);
  }
};

const setAuthorization = (content) => {
  console.log(content);
  const { auth } = content;
  storageSet('token', auth);
};

export const logoutAction = () => async (dispatch) => {
  storageRemove('token');
  window.location.pathname = '/login';
};
