import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import { Spinner } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createSchemaDataAction,
  createSchemaDataSuccessRedirectAction,
  getSchemaAction,
} from '../../redux/action/schema';
import ViewDropdown from '../../component/ViewDropdown';
import { convertViewFormikInitValues } from '../../utils/convertArrayofArraysToObjects';
import ConfirmModal from '../../component/ConfirmModal';
import '../../styles/View.css';

function ViewForm() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { schema, errorMessage } = useSelector((state) => state.schema);
  const [openBackModal, setOpenBackModal] = useState(false);
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const { search } = window.location;
  const query = new URLSearchParams(search).get('redr');

  const { id, formID } = params;

  const getSchema = useCallback(() => {
    if (formID) {
      dispatch(getSchemaAction(formID));
    }
  }, [dispatch, formID]);

  useEffect(() => {
    getSchema();
  }, [getSchema]);

  const handleInputType = (value) => {
    switch (value) {
      case 1:
        return 'date';
      case 2:
        return 'datetime-local';
      case 3:
        return 'text';
      case 4:
        return 'number';
      default:
        return 'date';
    }
  };

  const [initValues, setInitValues] = useState({});
  const initialValues = useCallback(() => {
    setInitValues({
      ...convertViewFormikInitValues(schema?.schemaField, 'fieldID'),
    });
  }, [schema?.schemaField]);

  useEffect(() => {
    initialValues();
  }, [initialValues]);

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: async (values, { setSubmitting }) => {
      setOpenSubmitModal(true);
      if (openSubmitModal) {
        setSubmitting(true);
        const newValues = {
          projectID: id,
          schemaID: formID,
          dataField: {
            ...values,
          },
        };

        const response = await dispatch(createSchemaDataAction(newValues));
        if (response?.success?.status === 1) {
          if (query) {
            window.location.href = query;
          } else {
            navigate(`/${id}/forms/${formID}/formResponse`);
          }
        }
        setSubmitting(false);
      }
    },
  });
  const { getFieldProps, handleSubmit, setFieldValue, isSubmitting } = formik;

  const handleOpenBackModal = () => {
    setOpenBackModal(true);
  };

  const handleCloseModal = () => {
    setOpenBackModal(false);
    setOpenSubmitModal(false);
  };

  const handleBack = () => {
    navigate(`/project/${params?.id}/forms`);
  };

  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.onpopstate = () => {
  //     setOpenBackModal(true);
  //   };

  //   // logic for showing popup warning on page refresh
  //   // window.onbeforeunload = function () {
  //   //   return 'Data will be lost if you refresh the page, are you sure?';
  //   // };
  // }, []);

  const dev = process.env.NODE_ENV !== 'production';

  if (!schema) {
    return (
      <div className="w-[80px] h-[80px] m-auto animate-pulse absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4">
        <img
          src="https://res.cloudinary.com/touch-and-pay-technologies-limited/image/upload/v1654441950/tap-website/logo_gzxyo7.png"
          alt="loading"
        />
      </div>
    );
  }

  return (
    <>
      {dev && (
        <div
          onClick={handleOpenBackModal}
          className="cursor-pointer w-full bg-white"
        >
          <aside className="text-gray-500 bg-white py-2 flex fixed top-5 left-5 lg:top-10 lg:left-10 z-50 w-full">
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                clipRule="evenodd"
              ></path>
            </svg>
            <p className="ml-2"> Back</p>
          </aside>
        </div>
      )}
      <ConfirmModal
        open={openSubmitModal}
        handleConfirm={handleSubmit}
        children={<p className="text-center">Do you really want to submit?</p>}
        onClose={handleCloseModal}
        isLoading={isSubmitting}
      />

      <ConfirmModal
        open={openBackModal}
        onClose={handleCloseModal}
        handleConfirm={handleBack}
        header={'Confirm'}
        children={
          <p className="text-center">Are you sure you want to go back?</p>
        }
      />
      <section className="container px-4 md:px-10 lg:px-60 mx-auto my-6 md:my-20 view mt-16">
        {schema ? (
          <>
            <header>
              <h2 className="text-2xl font-semibold uppercase">
                {schema?.schemaName}
              </h2>
              <small className="">Project ID : {schema?.projectID}</small>
            </header>
            <FormikProvider value={formik}>
              <form onSubmit={handleSubmit}>
                {schema?.schemaField?.map((field, key) => {
                  return (
                    field.hide === false && (
                      <aside
                        className="shadow py-2 px-4 rounded-md border my-6"
                        key={key}
                      >
                        {field.fieldType !== 5 ? (
                          <div className="relative my-6 w-full">
                            {field.behavior.includes(5) && (
                              <p className="text-red-400 -translate-y-4">*</p>
                            )}
                            <input
                              type={`${handleInputType(field.fieldType)}`}
                              {...getFieldProps(`${field.fieldID}`)}
                              required={field.behavior.includes(5)}
                              className="block w-full rounded-t-lg px-2.5 pb-2.5 pt-5 text-sm text-gray-900 bg-white dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#f47f30] peer"
                            />
                            <label
                              htmlFor={field.fieldID}
                              className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 origin-[0] left-0 peer-focus:text-[#f47f30] peer-focus:dark:text-[#f47f30] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
                              // placeholder='your answer'
                            >
                              {field.fieldName}
                            </label>
                          </div>
                        ) : (
                          <div className="py-2">
                            <p className="my-3">{field.fieldName}</p>
                            <ViewDropdown
                              behavior={field.behavior}
                              data={field.data}
                              onChange={(e) =>
                                setFieldValue(`${field.fieldID}`, e.value)
                              }
                            />
                          </div>
                        )}
                      </aside>
                    )
                  );
                })}
                <footer className="flex justify-between items-center">
                  <button
                    className="bg-[#f47f30] text-white py-2 px-3 rounded-md w-20"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Spinner
                          color="success"
                          size="sm"
                          className="mr-2"
                          aria-label="Success spinner example"
                        />
                      </>
                    ) : (
                      'Submit'
                    )}
                  </button>

                  {/* <p className="cursor-pointer hover:underline">Clear Form</p> */}
                </footer>
              </form>
            </FormikProvider>
          </>
        ) : (
          <div className=" h-[70vh] flex justify-center items-center">
            <section className="px-6 py-4 rounded-md border">
              <h3>{errorMessage}</h3>
              <Link to={`/`}>
                <small className="text-[#f47f30]">Go Home</small>
              </Link>
            </section>
          </div>
        )}
      </section>
    </>
  );
}

export default ViewForm;
