/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik, ErrorMessage, FormikProvider } from 'formik';
import * as yup from 'yup';
import { loginAction } from '../../redux/action/auth';
import { storageSet } from '../../utils';
import { ToastMessage } from '../../component/ToastMessage';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const { successMessage, errorMessage } = useSelector(
    (state) => state.project,
  );

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required('required'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      storageSet('u_em', values.email);
      const response = await dispatch(loginAction(values));
      if (response?.success?.status === 1) {
        setIsSuccess(true);
        setTimeout(() => {
          navigate(`/validate`);
        }, 2000);
      } else {
        setIsError(true);
      }
    },
  });

  const { handleSubmit, handleBlur, isSubmitting, getFieldProps } = formik;

  const handleCloseToast = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  return (
    <section className="w-screen h-screen flex justify-center items-center">
      <FormikProvider value={formik}>
        <form
          className="w-[400px] mx-auto shadow-md px-4 py-6 my-10"
          onSubmit={handleSubmit}
        >
          <div className="w-16 h-16 mx-auto">
            <img
              src={
                'https://frontendgroupdiag.blob.core.windows.net/websiteresource/tapsite/logo.png'
              }
              alt="Tap"
            />
          </div>
          <h1 className="text-center font-semibold text-xl mb-5">
            Welcome to Tap Forms
          </h1>
          {/* <h1 className="text-center font-semibold text-xl">Login</h1> */}
          <div className="relative my-6">
            <input
              type="text"
              onBlur={handleBlur}
              {...getFieldProps('email')}
              className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-[#f47f30] dark:focus:border-[#f47f30] focus:outline-none focus:ring-0 focus:border-[#f47f30] peer"
              placeholder=" "
            />
            <label
              htmlFor="email"
              className="absolute text-md text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5 peer-focus:text-[#f47f30] peer-focus:dark:text-[#f47f30] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4"
            >
              Email
            </label>
            <ErrorMessage
              name="email"
              render={(msg) => (
                <p id="filled_error_help" className="mt-2 text-xs text-red-400">
                  <span className="font-medium">{msg}</span>
                </p>
              )}
            />
          </div>

          <div className="flex justify-between items-center my-8">
            <button
              className="bg-[#f47f30] rounded-md text-white px-4 py-2 w-full"
              type="submit"
            >
              {isSubmitting ? (
                <>
                  <svg
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  loading...
                </>
              ) : (
                'Login'
              )}
            </button>
          </div>
        </form>
        {isSuccess && (
          <ToastMessage
            message={successMessage}
            success
            handleClose={handleCloseToast}
          />
        )}
        {isError && (
          <ToastMessage
            message={errorMessage}
            error
            handleClose={handleCloseToast}
          />
        )}
      </FormikProvider>
    </section>
  );
}

export default Login;
