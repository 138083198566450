import { Modal, Button, Spinner } from 'flowbite-react';

function ConfirmModal({
  open,
  onClose,
  header,
  children,
  handleConfirm,
  isLoading,
}) {
  return (
    <Modal show={open} size="md" popup={true} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <button
          className="bg-[#f47f30] py-[10px] px-4 rounded-lg text-white text-sm"
          onClick={handleConfirm}
          type="button"
        >
          {isLoading ? <Spinner /> : 'Confirm'}
        </button>
        <Button color="gray" onClick={onClose} type="button">
          Decline
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
