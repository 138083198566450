import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Spinner, Table, TextInput } from 'flowbite-react';
import { TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSchemaAction,
  getSchemaDataAction,
  getSchemaFilterObjAction,
} from '../../redux/action/schema';
import { getProjectAction } from '../../redux/action/project';
import { reverseCamelCase } from '../../utils/functionCamelCase';
import ViewDropdown from '../../component/ViewDropdown';
import { convertViewFormikInitValues } from '../../utils/convertArrayofArraysToObjects';
import { ExportCSV } from '../../component/ExportCSV';
import ConfirmModal from '../../component/ConfirmModal';
import '../../styles/FormData.css';
import '../../styles/Response.css';

export const FormData = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { schema, schemaData, schemaFilterObj } = useSelector(
    (state) => state.schema,
  );
  const { allProjects } = useSelector((state) => state.project);
  const [openModal, setOpenModal] = useState(false);

  const { id, formID } = params;

  const getSchemaData = useCallback(() => {
    dispatch(getSchemaDataAction({ schemaID: formID }));
    dispatch(getSchemaFilterObjAction(formID));
    dispatch(getProjectAction());
    dispatch(getSchemaAction(formID));
  }, [formID, dispatch]);

  useEffect(() => {
    getSchemaData();
  }, [getSchemaData]);

  const schemaName = schema?.schemaName;
  const projectName = allProjects?.find((proj) => proj.id === id)?.projectName;

  const tableData = schemaData?.map((data) => data.data);

  const filteredData = tableData?.filter((data) => {
    return Object.values(data)?.filter((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase()),
    )[0];
  });

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleBack = () => {
    navigate(`/project/${params?.id}/forms`);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.onpopstate = () => {
      setOpenModal(true);
    };

    // logic for showing popup warning on page refresh
    window.onbeforeunload = function () {
      return 'Data will be lost if you refresh the page, are you sure?';
    };
  }, []);

  const handleInputType = (value) => {
    switch (value) {
      case 1:
        return 'date';
      case 2:
        return 'datetime-local';
      case 3:
        return 'text';
      case 4:
        return 'number';
      default:
        return 'date';
    }
  };

  const [initValues, setInitValues] = useState({});

  const values = useCallback(() => {
    setInitValues({
      ...convertViewFormikInitValues(schemaFilterObj?.schemaField, 'fieldID'),
    });
  }, [schemaFilterObj?.schemaField]);

  useEffect(() => {
    values();
  }, [values]);

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const valuesArray = () =>
        // eslint-disable-next-line
        Object.entries(values).map((item) => {
          if (item[1] !== '') {
            return {
              fieldID: item[0],
              value: item[1],
            };
          }
        });

      const filteredValues = valuesArray()?.filter(
        (item) => item !== undefined,
      );

      const newValues = {
        schemaID: formID,
        filterBodyObj: filteredValues,
      };
      // eslint-disable-next-line
      const response = await dispatch(getSchemaDataAction(newValues));
      setSubmitting(false);
    },
  });
  const { getFieldProps, handleSubmit, setFieldValue, isSubmitting } = formik;

  const headers =
    schemaData?.length &&
    Object.keys(tableData[0])?.map((i) => reverseCamelCase(i));

  // modifyTableData();
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <div onClick={handleOpen} className="cursor-pointer">
        <aside className="text-gray-500 w-full bg-white py-2 flex fixed top-5 left-5 lg:top-10 lg:left-10 z-50">
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p className="ml-2"> Back</p>
        </aside>
      </div>
      <ConfirmModal
        open={openModal}
        onClose={handleClose}
        handleConfirm={handleBack}
        header={'Confirm'}
        children={
          <p className="text-center">Are you sure you want to go back?</p>
        }
      />
      <div className="Form-Data container mx-auto px-6 my-16">
        <section className="my-4">
          <h3 className="font-bold my-2 uppercase">Schema: {schemaName}</h3>
          <small className="">Project : {projectName}</small>
        </section>
        <div>
          {schemaFilterObj?.schemaField?.length > 0 && (
            <FormikProvider value={formik}>
              <form onSubmit={handleSubmit} className="">
                <aside className="shadow py-6 px-4 rounded-md border my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-6 ">
                  {schemaFilterObj?.schemaField?.map((field, key) => {
                    return (
                      // field.hide === false && (
                      <div key={key}>
                        {field.fieldType !== 5 ? (
                          <div className="relative mt-6 w-full">
                            <label
                              htmlFor={field.fieldID}
                              className="block text-md font-base text-black dark:text-gray-400 mb-2"
                            >
                              {field.fieldName}
                            </label>
                            <input
                              type={`${handleInputType(field.fieldType)}`}
                              {...getFieldProps(`${field.fieldID}`)}
                              className="w-full rounded-lg px-2.5 text-sm text-gray-900 bg-white dark:bg-gray-700 border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-[#f47f30] peer"
                            />
                          </div>
                        ) : (
                          <div className="py-2">
                            <p className="my-3">{field.fieldName}</p>
                            <ViewDropdown
                              behavior={field.behavior}
                              data={field.data}
                              onChange={(e) =>
                                e
                                  ? setFieldValue(`${field.fieldID}`, e.value)
                                  : setFieldValue(`${field.fieldID}`, '')
                              }
                            />
                          </div>
                        )}
                      </div>
                      // )
                    );
                  })}{' '}
                  <footer className="">
                    <div className="hidden md:block h-14 "></div>
                    <button
                      className="bg-[#f47f30] text-white py-2 px-3 rounded-md w-full md:w-20"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner
                            color="success"
                            size="sm"
                            className="mr-2"
                            aria-label="Success spinner example"
                          />
                        </>
                      ) : (
                        'Filter'
                      )}
                    </button>
                  </footer>{' '}
                </aside>
              </form>
            </FormikProvider>
          )}
        </div>
        <div className="shadow-md rounded-lg pb-10">
          <div className="border-b flex items-center justify-between py-8 px-2 md:px-8 ">
            <div className="w-[240px] md:w-[340px] relative mb-2">
              <TextInput
                id="search"
                defaultValue={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search..."
                sizing={'sm'}
              />
            </div>
            <div>
              <ExportCSV
                csvData={filteredData}
                total={filteredData?.length}
                fileName={schemaName}
                headers={headers}
              />
            </div>
          </div>
          {filteredData?.length > 0 ? (
            <>
              <Table>
                <Table.Head>
                  <Table.HeadCell>S/N</Table.HeadCell>
                  {Object.keys(tableData[0]).map((header, idx) => (
                    <Table.HeadCell key={idx}>
                      {reverseCamelCase(header)}
                    </Table.HeadCell>
                  ))}
                </Table.Head>
                <Table.Body className="divide-y">
                  {filteredData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage,
                    )
                    ?.map((data, idx) => (
                      <Table.Row
                        key={idx}
                        className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      >
                        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                          {idx + 1}
                        </Table.Cell>
                        {Object.values(data).map((value, idx) => (
                          <Table.Cell
                            key={idx}
                            className="whitespace-nowrap font-medium text-gray-900 dark:text-white"
                          >
                            {value}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <div className="text-center pt-10">no data</div>
          )}
        </div>
      </div>
    </>
  );
};
