/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <>
      <header className="flex justify-end py-4">
        <li className="list-none mt-4 mr-16"></li>
      </header>
      <main className="min-h-screen">
        <div className="container mx-auto">
          <div className="flex flex-col justify-center items-center h-75vh] text-center">
            <h2 className="text-secondary-500 dark:text-white">
              Sorry, page not found!
            </h2>
            <p className="font-normal mt-8 max-w-3xl">
              Sorry, we couldn’t find the page you’re looking for. Perhaps
              you’ve mistyped the URL? Make sure to check your spelling.
            </p>
            <img
              alt="page 404"
              src="https://res.cloudinary.com/touch-and-pay-technologies-limited/image/upload/v1654441966/tap-website/404_wadrn8.svg"
              className="h-96 object-contain my-28"
            />
            <Link to="/" className="px-16 py-6">
              Go to Home
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default Page404;
