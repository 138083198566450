export const convertArrayofArraysToObject = (arr) => {
  const obj = {};

  for (const pair of arr) {
    const [key, value] = pair;
    obj[key] = value;
  }

  return obj;
};

export const convertViewFormikInitValues = (array, key) => {
  const initialValue = {};
  return array?.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: '',
    };
  }, initialValue);
};

// const formatNewSchemaArray = () => {
//   const initialFormat = allSchemas?.map((sch) =>
//     sch?.schemaField?.map((field) => ({
//       schemaID: sch?.id,
//       schemaName: sch?.schemaName,
//       ...field,
//     }))
//   );

//   const filteredType = initialFormat
//     .flat()
//     ?.filter((item) => item.fieldType === 5);

//   return filteredType;
// };
