import axios from '.';

export const creatProjectRequest = (values) =>
  axios.post('/create/project', values).then((res) => res.data);

export const getProjectRequest = () =>
  axios.get('/fetch/projects').then((res) => res.data);

export const enableAccessProjectRequest = (values) =>
  axios.post('/invite', values).then((res) => res.data);

export const disableAccessProjectRequest = (values) =>
  axios.post('/remove', values).then((res) => res.data);
